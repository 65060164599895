<template>
  <div class="table-container">
    <div class="table-wrapper">
      <v-data-table
          v-model="selectVal"
          :headers="active_headers"
          :items="items"
          item-key="id"

          class="elevation-0 table custom-table table__without-checkbox"
          checkbox-color="primary"
          :header-props="{ sortIcon: '$vuetify.icons.iconsort' }"
          fixed-header
          disable-pagination
          :hide-default-footer="true"
          :loading="loading"
          :loading-text="loadingItemsText"
          :options.sync="options"
          must-sort
          :sort-desc="false"
          :sort-by="defaultSort"
      >

        <template v-slot:item="{ item, headers, isSelected, select }">
          <tr :data-id="item.id">
              <template
                v-for="( header, index ) in headers"
              >
<!--                <td-->
<!--                  :key="index"-->
<!--                  v-if="index === 0"-->
<!--                >-->
<!--                  <v-simple-checkbox-->
<!--                    :value="isSelected"-->
<!--                    @input="select($event)"-->
<!--                    color="primary"-->
<!--                    :ripple="false"-->
<!--                  ></v-simple-checkbox>-->
<!--                </td>-->

                <td
                  :key="index"
                  v-if="header.type === 'actions'"
                >
                  <v-layout align-center justify-center>
                    <v-btn
                      icon
                      color="green"
                      class="mt-1"
                      @click="editItem(item.id)"
                      :title="i18n.t('modules.customdata.entity.edit')"
                    ><v-icon small>$iconedit</v-icon></v-btn>
                    <v-btn
                      icon
                      color="red"
                      class="mt-1"
                      @click="deleteItem(item.id)"
                      :title="i18n.t('modules.customdata.entity.delete')"
                    ><v-icon small>$icondelete</v-icon></v-btn>
                  </v-layout>
                </td>

                <td
                  v-else
                  :key="index"
                >{{ item[header.value] }}</td>
              </template>
            </tr>
        </template>
      </v-data-table>
    </div>

    <div
        class="table-pagination"
        v-if="selectedRows.length < 1"
    >
      <div class="text-body-2">
        {{ $t('modules.customdata.table.pagination.showed') }}: {{ itemsLength }} {{ $t('modules.customdata.table.pagination.items') }}
      </div>

      <v-spacer></v-spacer>

      {{ /* Select Per Page */ }}
      <div class="d-flex align-center mr-5">
        <div class="text-body-2 mr-2">{{ $t('modules.customdata.table.pagination.rows_per_page') }}:</div>
        <v-select
            item-text="name"
            item-value="value"
            v-model="selectDefaultPerPage"
            :items="itemsPerPage"
            @change="resetPagination"
            dense
            outlined
            height="32"
            hide-details
            class="select-per-page"
        ></v-select>
      </div>
      {{ /* end Select Per Page */ }}

      <v-pagination
          v-model="page"
          :length="pageCount"
          :total-visible="(pageCount >= 3) ? '5' : '0'"
          @input="handlePageChange"
          circle
      ></v-pagination>
    </div>

  </div>
</template>

<script>
import i18n from "@/lang/i18n"
import { mapGetters } from "vuex";
import _ from 'lodash'

export default {
  name: "DynamicTable",

  props: {
    items: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
    selectedHeaders: {
      type: Array,
      default: () => [],
    },
    loadingItemsText: {
      type: String,
      default: i18n.t("modules.customdata.table.loading_items"),
    },
    itemsLength: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: true,
    },

    defaultPerPage: {
      type: Number,
      default: 5,
    },
    itemsPerPage: {
      type: Array,
      default: () => [],
    },

    pageCount: {
      type: Number,
      default: 0,
    },

    defaultSort: {
      type: String || Array,
      default: () => '' || []
    },
  },

  data: () => ({
    selectedRows: [],
    page: 1,
    itemsCount: 0,
    options:{},
    i18n,
  }),
  watch: {
    options: {
      handler (val, valBefore) {
        if (valBefore?.page)
          this.$emit("sortOptions", this.options);
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters([
      'custom_data_columns_data',
    ]),
    // showHeaders() {
    //   console.log(this.selectedHeaders, 'showHeaders')
    //   return (this.selectedHeaders)
    //     ? this.selectedHeaders.filter((s) => s.active === true)
    //     : this.headers;
    // },

    selectVal: {
      get() {
        return this.selected;
      },
      set(val) {
        this.selectedRows = val;
        this.$emit("selectedData", val);
      },
    },

    /**
     * @name selectDefaultPerPage
     * @description v-model for select Per Page Items
     */
    selectDefaultPerPage: {
      get() {
        return this.defaultPerPage;
      },
      set(value) {
        this.$emit("change-default-per-page", value);
      },
    },

    active_headers() {
      let arr = this.custom_data_columns_data.filter((c) => c.active === true)
      return _.sortBy(arr, 'position')
    },
  },

  methods: {
    /**
     * @description reset Pager on @change perPage v-select
     */
    resetPagination() {
      this.page = 1;
    },

    /**
     * @name handlePageChange
     * @param value
     * @description call on input/change for pagination and send to parent
     */
    handlePageChange(value) {
      this.$emit("change-page", value);
    },

    editItem(value) {
      this.$emit('edit-item', value)
    },
    deleteItem(value) {
      this.$emit('delete-item', value)
    },
  },
};
</script>

<style lang="scss" scoped>
.table-container::v-deep {
  display: flex;
  flex-direction: column;
  flex: auto;
}

/* Pagination */
.table-pagination::v-deep {
  border-top: 1px solid var(--v-gray-base);
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .select-per-page{
    max-width: 70px;
    &.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot {
      padding: 0 8px 0 12px;
      min-height: 32px;
    }
    &.v-select.v-input--dense .v-select__selection--comma{
      margin: 0;
      font-size: 14px;
      overflow: visible;
    }
    .v-input__append-inner{
      margin-top: 4px !important;
    }
  }

  .v-pagination__item{
    box-shadow: none;
    background: none;
    min-width: 28px;
    max-height: 28px;
    border-radius: 2px;
    border: 1px solid transparent;
    background-color: transparent !important;

    &.v-pagination__item--active {
      border-color: var(--v-primary-base);

      color: var(--v-primary-base);
    }
  }

  .v-pagination {
    .v-pagination__navigation {
      background: transparent;
      box-shadow: none;
    }
  }
}
/* end Pagination */

.table-wrapper {
  height: 300px;
  flex: auto;

  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  ::-webkit-scrollbar-track {
    background: var(--v-gray-base);
  }
  ::-webkit-scrollbar-thumb {
    background: var(--v-primary-base);
    border-radius: 2px;
  }
}
.table::v-deep {
  flex: auto;
  height: 100%;

  table {
    border-top: none;
  }

  .v-input--selection-controls__input {
    margin-right: 8px;
  }

  .v-data-table__wrapper {
    height: 100%;
  }
  th{
    border-top: 1px solid var(--v-gray-base);

    /* Sort icon */
    &:not(:first-child) {
      padding-right: 27px !important;
      & > span.v-icon {
        position: absolute;
        right: 12px;
        top: 18px;

        width: 10px !important;
        height: 12px !important;

        svg {
          width: 10px !important;
          height: 12px !important;
        }
      }
    }
  }
  th, td{
    /* style for checkbox selected*/
    &:nth-child(1) {
      padding-left: 10px;
      padding-right: 2px;
    }
    &:nth-child(2) {
      padding-left: 10px;
      border-left: none;
    }
  }

  &.table__without-checkbox {
    th{
      &:first-child {
        padding-right: 27px !important;
        & > span.v-icon {
          position: absolute;
          right: 12px;
          top: 18px;

          width: 10px !important;
          height: 12px !important;

          svg {
            width: 10px !important;
            height: 12px !important;
          }
        }
      }
    }
  }
}
/* Custom table cols */
.table-col-status {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-table::v-deep {
  border-radius: 0;

  table {
    border: 1px solid var(--v-gray-base);
  }
  tr {
    th {
      background-color: var(--v-gray2-base) !important;
      white-space: nowrap;
      font-weight: 700;

    }
    th, td {
      font-size: 14px !important;
      color: var(--v-black-base) !important;

      &:not(:last-child) {
        border-right: 1px solid var(--v-gray-base);
      }
    }
    &:first-child {

    }
    &:last-child {
      td {
        //border-bottom: 1px solid var(--v-gray-base);
      }
    }
  }
}
</style>
