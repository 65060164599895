import axios from '../../axios-middleware';
import i18n from '@/lang/i18n.js';
import HELPERS from '@/modules/OperatorPanel/helpers';
import Vue from "vue";

const state = {
  operator_panel: {
    isGroupsPanelOpen: true,
    isRightSideBarOpen: false,

    isRightSideBarHistoryPanelOpen: false,
    isRightSideBarHistoryItemId: '',

    isRightSideBarStepsHistoryPanelOpen: false,


    isTopSideBarOpen: false,
    isUserSettingsOpen: false,
    isAddChangeThemeModalOpen: false,
    isNewRoomModalOpen: false,
    isChatNewClientModalOpen: false,
    isChatOpClientModalOpen: false,
    isDialogSearchModalOpen: false,
    isAddChatUserModalOpen: {
      open: false,
      type: '', // operator, supervisor
    },
    langList: [],
    defaultLang: null,
    confirm: {
      open: false,
      title: '',
      text: '',
      cancel: () => console.log('cancel confirm'),
      accept: () => console.log('accept confirm'),
    },
    isCloseBtnClicked: false,
    settings: [],
    settingStylesCss: '',
    modalLeaveChatStatus: {
      isOpen: false,
      cb: null
    },

    isHistoryPanelOpen: false,
    isHistoryPanelRightOpen: false,
  },
};

const mutations = {
  OP_SET_GROUPS_PANEL_OPEN(state, isOpen) {
    state.operator_panel.isGroupsPanelOpen = isOpen;
  },

  OP_SET_RIGHT_SIDEBAR_OPEN(state, isOpen) {
    state.operator_panel.isRightSideBarOpen = isOpen;
  },

  OP_SET_RIGHT_SIDEBAR_HISTORY_PANEL_OPEN(state, isOpen) {
    state.operator_panel.isRightSideBarHistoryPanelOpen = isOpen;
  },

  OP_SET_RIGHT_SIDEBAR_HISTORY_ITEM_ID(state, id) {
    state.operator_panel.isRightSideBarHistoryItemId = id;
  },

  OP_SET_RIGHT_SIDEBAR_STEPS_HISTORY_PANEL_OPEN(state, isOpen) {
    state.operator_panel.isRightSideBarStepsHistoryPanelOpen = isOpen;
  },

  OP_SET_TOP_SIDEBAR_OPEN(state, isOpen) {
    state.operator_panel.isTopSideBarOpen = isOpen;
  },

  OP_SET_USER_SETTINGS_OPEN(state, isOpen) {
    state.operator_panel.isUserSettingsOpen = isOpen;
  },
  OP_SET_HISTORY_PANEL_OPEN(state, isOpen) {
    state.operator_panel.isHistoryPanelOpen = isOpen;
  },

  OP_SET_HISTORY_PANEL_RIGHT_OPEN(state, isOpen) {
    state.operator_panel.isHistoryPanelRightOpen = isOpen;
  },

  OP_SET_ADD_CHAT_USER_OPEN(state, { open, type }) {
    state.operator_panel.isAddChatUserModalOpen = { open, type };
  },

  OP_SET_LANG_LIST(state, langList) {
    state.operator_panel.langList = langList;
  },

  OP_SET_DEFAULT_LANG(state, langId) {
    state.operator_panel.defaultLang = langId;
  },

  OP_SET_CHANGE_THEME_MODAL_OPEN(state, isOpen) {
    state.operator_panel.isAddChangeThemeModalOpen = isOpen;
  },

  OP_SET_DIALOG_SEARCH_MODAL_OPEN(state, isOpen) {
    state.operator_panel.isDialogSearchModalOpen = isOpen;
  },

  OP_SET_NEW_ROOM_MODAL_OPEN(state, isOpen) {
    state.operator_panel.isNewRoomModalOpen = isOpen;
  },

  OP_SET_CHAT_NEW_CLIENT_MODAL_OPEN(state, isOpen) {
    state.operator_panel.isChatNewClientModalOpen = isOpen;
  },

  OP_SET_CHAT_OP_CLIENT_MODAL_OPEN(state, isOpen) {
    state.operator_panel.isChatOpClientModalOpen = isOpen;
  },

  OP_SET_CONFIRM_MODAL(state, settings) {
    const defaultConfirmSettings = {
      open: false,
      title: i18n.t('modules.op.confirm_modal.attention'),
      text: i18n.t('modules.op.confirm_modal.confirm_action'),
      accept: () => console.log('accept confirm'),
    };

    const currentSettings = state.operator_panel.confirm;

    const options = settings.open
      ? { ...defaultConfirmSettings, ...settings }
      : { ...currentSettings, ...settings };

    state.operator_panel.confirm = options;
  },

  OP_SET_CLOSE_BTN_CLICKED(state, isClicked) {
    state.operator_panel.isCloseBtnClicked = isClicked;
  },

  OP_SET_SETTINGS(state, data) {
    state.operator_panel.settings = data;
  },

  OP_SET_MODAL_LEAVE_CHAT_STATUS(state, statusObj) {
    state.operator_panel.modalLeaveChatStatus = statusObj;
  },

  OP_SET_SETTING_STYLES_CSS(state, value) {
    state.operator_panel.settingStylesCss = value;
  }
};

const actions = {
  async opGetSettings({ commit }) {
    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/ent/settings/list/`;

    await axios
      .post(url)
      .then((response) => {
        const settings = response.data?.data?.settings || [];

        commit('OP_SET_SETTINGS', settings);
      })
      .catch((err) => {
        HELPERS.handleError([
          err,
          i18n.t('common.error'),
          i18n.t('common.error_get'),
        ]);
      });
  },

  axiosOpSettings ( { commit }, {request, list} ) {
    const token = Vue.$cookies.get('token_c');
    const setting = list === '' ? 'setting' : 'settings'

    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/ent/${setting}/${list}`,
          request,
          {
            headers: {
              'X-CSRF-TOKEN': token,
            },
          }
        )
        .then((response) => {
          commit('OP_SET_SETTINGS', response.data?.data?.settings || []);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async opGetLangList({ commit }) {
    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/lang/list/`;

    const langList = await axios
      .get(url)
      .then((response) => {
        const langs = response.data?.data?.langs || [];

        const isDefault = (lang) => lang.default === 1;
        const defaultLang = langs.find(isDefault);
        const defaultLangId = defaultLang?.id || null;

        commit('OP_SET_DEFAULT_LANG', defaultLangId);

        return langs;
      })
      .catch((err) => {
        HELPERS.handleError([
          err,
          i18n.t('common.error'),
          i18n.t('common.error_get'),
        ]);
        return [];
      });

    commit('OP_SET_LANG_LIST', langList);
  },

  opSetGroupsPanelOpen({ commit }, isOpen) {
    commit('OP_SET_GROUPS_PANEL_OPEN', isOpen);
  },

  opSetRightSidebarOpen({ commit, dispatch }, isOpen) {
    commit('OP_SET_RIGHT_SIDEBAR_OPEN', isOpen);

    if (!isOpen) {
      //close history list panel
      dispatch("opSetRightSidebarHistoryPanelOpen", '');

      //clear steps history
      dispatch('opCLearClientStepsHistory')
      dispatch('opSetRightSidebarStepsHistoryPanelOpen', false)
    }
  },

  opSetRightSidebarHistoryPanelOpen({ commit, dispatch }, isOpen) {
    if (!isOpen) {
      dispatch("opSetRightSidebarHistoryItemId", '');
    }
    commit('OP_SET_RIGHT_SIDEBAR_HISTORY_PANEL_OPEN', isOpen);
  },
  opSetRightSidebarHistoryItemId({ commit, dispatch }, id) {
    if (id === undefined) {
      dispatch("opSetRightSidebarHistoryPanelOpen", false);
    }

    commit('OP_SET_RIGHT_SIDEBAR_HISTORY_ITEM_ID', id);
  },
  opSetRightSidebarStepsHistoryPanelOpen({ commit }, isOpen) {
    commit('OP_SET_RIGHT_SIDEBAR_STEPS_HISTORY_PANEL_OPEN', isOpen);
  },

  opSetTopSidebarOpen({ commit }, isOpen) {
    commit('OP_SET_TOP_SIDEBAR_OPEN', isOpen);
  },

  opSetUserSettingsOpen({ commit }, isOpen) {
    commit('OP_SET_USER_SETTINGS_OPEN', isOpen);
  },
  opSetHistoryPanelOpen({ commit }, isOpen) {
    commit('OP_SET_HISTORY_PANEL_OPEN', isOpen);
  },
  opSetHistoryPanelRightOpen({ commit }, isOpen) {
    commit('OP_SET_HISTORY_PANEL_RIGHT_OPEN', isOpen);
  },

  opSetAddChatUserModalOpen({ commit }, { open, type }) {
    commit('OP_SET_ADD_CHAT_USER_OPEN', { open, type });
  },

  opSetNewRoomModalOpen({ commit }, isOpen) {
    commit('OP_SET_NEW_ROOM_MODAL_OPEN', isOpen);
  },

  opSetChatNewClientModalOpen({ commit }, isOpen) {
    commit('OP_SET_CHAT_NEW_CLIENT_MODAL_OPEN', isOpen);
  },

  opSetChatOpClientModalOpen({ commit }, isOpen) {
    commit('OP_SET_CHAT_OP_CLIENT_MODAL_OPEN', isOpen);
  },

  opSetChangeThemeModalOpen({ commit }, isOpen) {
    commit('OP_SET_CHANGE_THEME_MODAL_OPEN', isOpen);
  },

  opSetConfirmModal({ commit }, settings) {
    commit('OP_SET_CONFIRM_MODAL', settings);
  },

  opSetDialogSearchModalOpen({ commit }, isOpen) {
    commit('OP_SET_DIALOG_SEARCH_MODAL_OPEN', isOpen);
  },

  opSetCloseBtnClicked({ commit }, isClicked) {
    commit('OP_SET_CLOSE_BTN_CLICKED', isClicked);
  },

  opSetModalLeaveChatStatus({ commit }, statusObj) {
    commit("OP_SET_MODAL_LEAVE_CHAT_STATUS", statusObj);
  },

  /**
   * @name opSetSettingStyleCss
   * @description for change value in CODE EDITOR
   * @param commit
   * @param value
   */
  opSetSettingStyleCss ({ commit }, value) {
    commit("OP_SET_SETTING_STYLES_CSS", value);
  }
};

const getters = {
  opIsGroupsPanelOpen: (state) => state.operator_panel.isGroupsPanelOpen,

  opIsRightBarOpen: (state) => state.operator_panel.isRightSideBarOpen,

  opIsRightBarHistoryPanelOpen: (state) => state.operator_panel.isRightSideBarHistoryPanelOpen,
  opIsRightBarStepsHistoryPanelOpen: (state) => state.operator_panel.isRightSideBarStepsHistoryPanelOpen,
  opIsRightBarHistoryItemId: (state) => state.operator_panel.isRightSideBarHistoryItemId,

  opIsTopBarOpen: (state) => state.operator_panel.isTopSideBarOpen,

  opIsUserSettingsOpen: (state) => state.operator_panel.isUserSettingsOpen,
  opIsHistoryPanelOpen: (state) => state.operator_panel.isHistoryPanelOpen,
  opIsHistoryPanelRightOpen: (state) => state.operator_panel.isHistoryPanelRightOpen,

  opIsLeftSideBarOpen: (state, getters) => {
    const { opIsUserSettingsOpen, opIsGroupsPanelOpen } = getters;
    return opIsUserSettingsOpen || opIsGroupsPanelOpen;
  },

  opIsAddChatUserModalOpen: (state) => {
    return state.operator_panel.isAddChatUserModalOpen;
  },

  opIsAddChangeThemeModalOpen: (state) => {
    return state.operator_panel.isAddChangeThemeModalOpen;
  },

  opIsNewRoomModalOpen: (state) => {
    return state.operator_panel.isNewRoomModalOpen;
  },

  opIsChatNewClientModalOpen: (state) => {
    return state.operator_panel.isChatNewClientModalOpen;
  },

  opIsChatOpClientModalOpen: (state) => {
    return state.operator_panel.isChatOpClientModalOpen;
  },

  opIsDialogSearchModalOpen: (state) => {
    return state.operator_panel.isDialogSearchModalOpen;
  },

  opConfirmModal: (state) => state.operator_panel.confirm,

  opIsCloseBtnClicked: (state) => state.operator_panel.isCloseBtnClicked,

  opIsSettings: (state) => state.operator_panel.settings,

  opModalLeaveChatStatus: (state) => {
    return state.operator_panel.modalLeaveChatStatus;
  },

  opGetSettingStylesCss: (state) => {
    return state.operator_panel.settingStylesCss;
  },

  opGetLangList: (state) => {
    return state.operator_panel.langList
  }
};

export default {
  state,
  mutations,
  actions,
  getters,
};
