<template>
  <v-card
    v-if="custom_data_tables.length === 0 && modulesCheck"
    max-width="600"
    class="modules-list-empty mt-5"
  >
    <v-card-title class="justify-center">
      {{$t('modules.op.texts.empty_modules_tables_title')}}
    </v-card-title>
    {{$t('modules.op.texts.empty_modules_tables_text')}}
    <v-card-text>
    </v-card-text>
  </v-card>

  <div class="cd__container" v-else>
    {{ /* Top navigation for table  */ }}
    <div class="cd__top-nav">
      <div class="cd__right">
        <v-tabs
          background-color="transparent"
          class="mr-4"
        >
          <v-tab
            v-for="( item, i ) in custom_data_tables"
            :key="i"
            @click="loadData(item)"
            :title="item.description"
          >{{item.name}}</v-tab>
        </v-tabs>
        <v-spacer></v-spacer>

        {{ /* Show/Hide Filters and Saved Filters List */ }}
        <v-layout
          align-center
          class="v-custom-menu__list--filter-btns mr-3"
          :class="{ 'active' : toggleActiveFilter }"
        >
          <v-btn
            class="filter-btns__btn-show"
            color=""
            height="48"
            max-width="48"
            min-width="48"
            elevation="0"
            @click="showFilters = !showFilters"
            text
          >
            <v-icon v-if="!showFilters">mdi-filter</v-icon>
            <v-icon v-else color="primary">mdi-filter-off</v-icon>
          </v-btn>

          <v-divider
            v-if="custom_data_saved_filters.length"
            vertical />

          <v-menu
            v-if="custom_data_saved_filters.length"
            offset-y
            rounded='sm'
            bottom
            min-width="208"
            nudge-bottom="12"
            nudge-left="14"
            :close-on-content-click=false
            content-class="v-custom-menu__list elevation-0"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :color="toggleActiveFilter ? 'primary' : 'gray'"
                elevation="0"
                text
                v-bind="attrs"
                v-on="on"
                @click="setHeaderColumnsFromState()"
                class="filter-btns__btn-menu"
              >
                <v-icon v-if="attrs['aria-expanded'] === 'true'">mdi-menu-up</v-icon>
                <v-icon v-else>mdi-menu-down</v-icon>
              </v-btn>
            </template>

            <v-list dense>
              <v-subheader>{{ $t("modules.customdata.entity.saved_filters") }}</v-subheader>

              <div class="v-custom-menu__list--wrapper with_fixed-height">
                <vue-scroll>
                  <v-list-item-group
                    v-model="toggleActiveFilter"
                    color="primary"
                  >
                      <template
                        v-for="(item, i) in custom_data_saved_filters">
                        <v-list-item
                          :key="`item-${i}`"
                          :value="item"
                          v-ripple="false"
                        >
                          <v-list-item-content>
                            <v-list-item-title

                              v-text="item.name"></v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-icon
                            @click="onDeleteFilter(item.name)"
                          ><v-icon size="14" color="red">$icondelete</v-icon>
                          </v-list-item-icon>

                        </v-list-item>
                      </template>
                  </v-list-item-group>
                </vue-scroll>
              </div>

            </v-list>
          </v-menu>

        </v-layout>

        {{ /* end Show/Hide and Filters List */ }}

        {{ /* Show/Hide table headers toggle */ }}
        <v-menu
          offset-y
          rounded='sm'
          bottom
          min-width="208"
          nudge-bottom="12"
          nudge-left="14"
          :close-on-content-click=false
          content-class="v-custom-menu__list elevation-0"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              height="48"
              max-width="68"
              min-width="68"
              color="white"
              elevation="0"
              v-bind="attrs"
              v-on="on"
              class="mr-3 v-custom-menu__list--btn"
            >
              <v-icon dark>
                $vuetify.icons.icontableedit
              </v-icon>
              <v-icon v-if="attrs['aria-expanded'] === 'true'">mdi-menu-up</v-icon>
              <v-icon v-else>mdi-menu-down</v-icon>
            </v-btn>
          </template>

          <v-list dense>
            <v-subheader>{{ $t("modules.customdata.entity.title_column_chooser") }}</v-subheader>
            <div class="v-custom-menu__list--wrapper with_fixed-height">
              <vue-scroll>
                <v-list-item-group multiple>
                  <draggable
                    @end="customDataChangeOrderColumn($event)"
                    handle=".drag_btn"
                    class="list-group"
                    :disabled="!enabled"
                    ghost-class="draggable-ghost"
                  >
                    <template
                      v-for="(item, i) in headerColumns">
                      <v-list-item
                        :key="`item-${i}`"
                        :value="item"
                        v-ripple="false"
                        :class="'dataitemname__' + item.name"
                      >

                        <span class="drag_btn">
                          <v-icon>mdi-drag</v-icon>
                        </span>
                        <v-list-item-action>
                          <v-checkbox
                            :input-value="item.active"
                            color="primary"
                            dense
                            @change="
                              customDataToggleColumnActive(
                                {
                                  name: item.name,
                                  value: $event,
                                }
                              )"
                          ></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title v-text="item.text"></v-list-item-title>
                        </v-list-item-content>

                      </v-list-item>
                    </template>
                  </draggable>
                </v-list-item-group>
              </vue-scroll>
            </div>

            <v-layout
              align-center
              class="px-6 mt-6"
            >
              <v-btn
                small
                color="primary"
                elevation="0"
                class="mr-6"
                @click="
                  custom_data_toggle_active_filter
                  ? onSaveFilters({name: custom_data_toggle_active_filter.name, with_columns: true})
                  : customDataAxiosSaveColumnsData({name: multiple_entity, reset: false})
                "
                v-text="
                  custom_data_toggle_active_filter
                  ? $t('modules.customdata.columns_chooser.save_columns_for_filter')
                  : $t('modules.customdata.columns_chooser.save_columns')
                "
              ></v-btn>

              <v-btn
                small
                color="primary"
                outlined
                elevation="0"
                @click="
                  custom_data_toggle_active_filter
                  ? resetColumnsChooserForFilter()
                  : resetColumnsChooser()
                "
              >{{ $t("modules.customdata.columns_chooser.clear_columns") }}</v-btn>
            </v-layout>

          </v-list>

        </v-menu>
        {{ /* end Show/Hide table headers toggle */ }}

        {{ /* menu Actions for Table */ }}
        <v-menu
          offset-y
          rounded='sm'
          bottom
          min-width="248"
          nudge-bottom="12"
          nudge-left="14"
          content-class="v-custom-menu__list elevation-0"
          :close-on-content-click=false
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              height="48"
              color="white"
              elevation="0"
              v-bind="attrs"
              v-on="on"
              class="mr-3 v-custom-menu__list--btn"
            >{{ $t("modules.customdata.entity.actions") }}
            </v-btn>
          </template>

          <v-list dense>
            <div class="v-custom-menu__list--wrapper">
              <v-list-item-group>
                <v-list-group>
                  <template v-slot:activator>
                    <v-list-item-icon>
                      <v-icon>mdi-download</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                      {{i18n.t('modules.customdata.entity.export')}}
                    </v-list-item-title>
                  </template>

                  <v-list-item
                    v-for="(item, index) in exportType"
                    :key="index"
                    v-on:click="getExportFile(item)"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{ item }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                </v-list-group>

                <v-list-item v-if="false">
                  <v-list-item-icon>
                    <v-icon>mdi-upload</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    Import
                  </v-list-item-title>
                </v-list-item>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="red">mdi-delete</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title
                    @click="clearModel"
                  >{{ $t('modules.customdata.clear') }}</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </div>
          </v-list>
        </v-menu>
        {{ /* end menu Actions for Table */ }}

        {{ /** ADD BUTTON **/}}
        <v-btn
          elevation="0"
          color="primary"
          @click="openModal('create_mode')"
        >
          <v-icon left> mdi-plus </v-icon>
          {{ i18n.t('modules.customdata.entity.add') }}
        </v-btn>
        {{ /** end ADD BUTTON **/}}

      </div>
    </div>
    {{ /* end Top navigation for table */ }}

    <div
      class="cd__bottom-nav"
     v-if="load"
    >
      <div
        class="cd__left"
        v-show="showFilters"
        key="3"
      >
        {{ /* Filters Component */ }}
        <DynamicFilters
          :filtersList="custom_data_filters"
          :linked-tables-values="custom_data_linked_tables_values"
          @apply="getItems()"
          :savedFiltersList="custom_data_saved_filters"
          @save="onSaveFilters($event)"
          @delete-filter="onDeleteFilter($event)"
          @reset-columns="resetColumnsChooserForFilter()"
        />
        {{ /* end Filters Component */ }}
      </div>

      <div class="cd__right cd__table">
        {{ /* selected table description */ }}
        <div class="table-descr text-caption">{{model_description}}</div>

        <DynamicTable
          :items="items || []"
          :headers="headers"
          :selectedHeaders="custom_data_columns_data"
          :searchInTable="searchInTable"
          :loading="loading"
          :items-length="length"
          :page-count="pageCount"
          :default-per-page="defaultPerPage"
          :items-per-page="itemsPerPage"
          @change-default-per-page="changeDefaultPerPage($event)"
          @change-page="changePage($event)"
          @sortOptions="onSort"
          @edit-item="editItem($event)"
          @delete-item="deleteItem($event)"
          :default-sort="'id'"
          ref="DynamicTable"
        />
      </div>
    </div>

    {{/** MODAL WINDOW EDIT/ADD ENTITY **/}}
    <template>
      <div class="text-center">
        <v-navigation-drawer
          v-model="modal"
          class="cd_modal"
          absolute
          temporary
          right
          width="50%"
        >
          <v-form ref="form" v-model="form_valid" class="settings-form">
            <v-card elevation="0">
              <v-card-title class="justify-center">
                <template v-if="modal_create_mode">{{i18n.t('modules.customdata.entity.modal_title_create')}}</template>
                <template v-else>{{i18n.t('modules.customdata.entity.modal_title')}}</template>
              </v-card-title>
              <v-card-text>
                <v-container grid-list-md>
                  <v-layout wrap>
                    {{/** ENTITY FIELDS **/}}
                    <template v-for="(field, key) in entity_fields">

                      <v-flex xs12 :key="key" >
                        {{/* print custom fields */}}
                        <template v-if="field.hasOwnProperty('association')">
                          <v-select
                            outlined
                            dense
                            v-model.trim="item_obj[key]"
                            :label="returnFilterKeyOrLabel(key, field)"
                            item-text="name"
                            item-value="id"
                            :items="custom_data_linked_tables_values[key]"
                          />
                        </template>

                        <template v-else>
                          {{/*STRING FIELDS*/}}
                          <v-text-field
                            outlined
                            dense
                            v-if="['STRING'].includes(field.type)"
                            v-model.trim="item_obj[key]"
                            :label="returnFilterKeyOrLabel(key, field)"
                            :rules="field.rules"
                          ></v-text-field>

                          {{/*TEXTAREA FIELDS*/}}
                          <v-textarea
                            outlined
                            dense
                            v-if="['TEXT'].includes(field.type)"
                            v-model.trim="item_obj[key]"
                            :label="returnFilterKeyOrLabel(key, field)"
                            :rules="field.rules"
                            auto-grow
                            rows="2"
                          ></v-textarea>

                          {{/*INTEGER FIELDS*/}}
                          <v-text-field
                            outlined
                            dense
                            v-if="['INTEGER'].includes(field.type)"
                            v-model.trim="item_obj[key]"
                            :label="returnFilterKeyOrLabel(key, field)"
                            :rules="[rules.onlyDigits]"
                          ></v-text-field>

                          {{/*FLOAT FIELDS*/}}
                          <v-text-field
                            outlined
                            dense
                            v-if="['FLOAT'].includes(field.type)"
                            v-model.trim="item_obj[key]"
                            :label="returnFilterKeyOrLabel(key, field)"
                            :rules="[rules.floats]"
                          ></v-text-field>

                          {{/*BOOL FIELDS*/}}
                          <v-checkbox
                            v-if="field.type === 'BOOLEAN'"
                            v-model="item_obj[key]"
                            :label="returnFilterKeyOrLabel(key, field)"
                            hide-details
                            :rules="field.rules"
                            class="mt-0 mb-4"
                          ></v-checkbox>

                          {{/*DATE FIELDS*/}}
                          <v-menu
                            v-if="['DATE'].includes(field.type)"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                outlined
                                dense
                                v-model.trim="item_obj[key]"
                                :label="returnFilterKeyOrLabel(key, field)"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                clearable
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="item_obj[key]"
                              no-title
                              :locale="i18n.locale"
                              scrollable
                            >
                            </v-date-picker>
                          </v-menu>

                        </template>
                      </v-flex>
                    </template>

                  </v-layout>
                </v-container>
              </v-card-text>

              <v-footer>
                <v-layout justify-start>
                  <v-btn
                    class="mr-4"
                    color="primary"
                    elevation="0"
                    @click="save"
                    small
                  >
                    <template v-if="modal_create_mode">{{i18n.t('common.create')}}</template>
                    <template v-else>{{i18n.t('common.save')}}</template>

                  </v-btn>
                  <v-btn
                    color="primary"
                    elevation="0"
                    outlined
                    @click="modal = false"
                    small
                  >{{ i18n.t('common.close') }}</v-btn>
                </v-layout>
              </v-footer>
            </v-card>
          </v-form>
        </v-navigation-drawer>
      </div>
    </template>

  </div>
</template>

<script>
import DynamicTable from "./../../components/DynamicTable.vue";
import DynamicFilters from "./../../components/DynamicFilters.vue";
import {mapActions, mapGetters} from "vuex";
import draggable from 'vuedraggable';

import customDataMixin from './customDataMixin';
import Vue from "vue";
import axios from "axios";
import i18n from '@/lang/i18n';

export default {
  name: "CustomDataTab",

  mixins: [customDataMixin],

  components: {
    DynamicTable,
    DynamicFilters,
    draggable,
  },

  data: () => ({
    i18n,
    enabled: true,

    //table data
    headers: [],
    selectedHeaders: [],
    headerColumns: [],

    // counters
    defaultPerPage: 18,
    itemsPerPage: [5, 10, 18, 50, 100],
    pageCount: 0,
    loading: false,

    //search table
    searchInTable: "",

    //Filters data
    filtersList: {},

    //selected table rows
    selected: [],

    dataInitial: {
      "offset": 0,
      "limit": 18,
      "order": {
        "sortBy": "id",
        "sortDesc": false,
      }
    },

    //new
    load: false,
    modulesCheck: false,
    multiple_entity: null,
    model_description:null,
    entity_fields: [],
    entity: null,

    length: null,

    item_obj: {
      id: null,
    },
    item_empty_obj: {},
    multipleSelectItems: [],

    entity_data: null,

    modal: false,
    form_valid: true,
    modal_create_mode: false,

    exportType: ['csv', 'xls', 'pdf', 'xml', 'html'],

    showFilters: true,
    filters: [],

    linkedTablesValues: {},
  }),

  mounted: async function () {
    await this.CustomDataAxiosGetTables();
    if(this.custom_data_tables) {
      this.modulesCheck = true
      this.multiple_entity = this.custom_data_tables[0]?.name
      this.model_description = this.custom_data_tables[0].description
      this.entity_fields = this.custom_data_tables[0].columns_json
    }
  },

  computed: {
    ...mapGetters([
      'rules',
      'custom_data_tables',
      'custom_data_filters',
      'custom_data_linked_tables_values',
      'custom_data_selected_filters',
      'custom_data_saved_filters',
      'custom_data_columns_data',
      'custom_data_columns_data_activated',
      'custom_data_saved_columns_data',
      'custom_data_default_columns_data',

      'custom_data_toggle_active_filter',
    ]),

    items: function () {
      let items = [];

      if (this.entity_data) {
        for (let item of this.entity_data) {
          if (item.id) {
            items.push(item);
          }
        }
      }
      return items;
    },

    //saved Filters ist toggle
    toggleActiveFilter: {
      get: function() {
        return this.custom_data_toggle_active_filter
      },
      set: async function(value) {
        if (!value) value = null
        await this.customDataSetToggleActiveFilter(value)

        //for updating columns list in this component
        this.setHeaderColumnsFromState()
        // this.headerColumns = this._.cloneDeep(this.custom_data_columns_data)
      },
    },
  },

  methods: {
    ...mapActions([
      'CustomDataAxiosGetTables',
      'CustomDataAxiosClearModel',
      'customDataToggleColumnActive',
      'customDataChangeOrderColumn',
      'customDataAxiosSaveColumnsData',
      'customDataResetColumnsDataToDefault',
      'customDataResetColumnsDataForFilterToSaved',
      'customDataSetToggleActiveFilter',
      'customDataAxiosSaveFilters',
    ]),

    onSort(data){
      if (data.sortBy.length > 0){
        this.dataInitial.order.sortBy = data.sortBy[0];
        this.dataInitial.order.sortDesc = data.sortDesc[0];
        this.getItems();
      }
    },

    /**
     * @name changeDefaultPerPage
     * @param value
     * @description change default page in pagination for child
     */
    changeDefaultPerPage(value) {
      this.defaultPerPage = value;
      this.dataInitial.offset = 0;
      this.dataInitial.limit = this.defaultPerPage;
      this.getItems();
    },

    /**
     * @method
     * @name changePage
     * @description server side Pagination
     * @param value
     */
    changePage(value) {
      this.dataInitial.offset = value > 1 ? (this.defaultPerPage * value) - this.defaultPerPage : 0;
      this.getItems();
    },

    /**
     * @name loadData
     * @description trigger axios on tab with tables changed
     * @param item
     * @returns {Promise<void>}
     */
    async loadData(item) {
      this.multiple_entity = item.name
      this.model_description = item.description
      this.entity_fields = item.columns_json
    },

    /**
     * @name getItems
     * @description main trigger function for Get items
     * @returns {Promise<void>}
     */
    async getItems() {
      this.entity_data = await this.getCustomEntityList(this.multiple_entity);
    },

    /**
     * @name getCustomEntityList
     * @description axios for get Items
     * @param multiple_entity
     * @returns {Promise<null>}
     */
    async getCustomEntityList(multiple_entity) {
      let data = null;
      const token = Vue.$cookies.get('token_e')
      const engine_url = this.engine_settings.active_url;
      const bot_id = this.bot_settings.active_id;
      let url = `${engine_url}/kw/custom_modules/${this.multiple_entity}/list?`

      // set order param
      let orderValue = this.dataInitial.order.sortDesc === true ? 'desc' : 'asc'
      url += '&order[' + this.dataInitial.order.sortBy +']=' + orderValue;

      //set filters
      if(Object.keys(this.custom_data_selected_filters).length !== 0) {
        url = this.setFiltersUrl(url)
      }

      let params = {
        bot_id: bot_id,
        limit: this.dataInitial?.limit,
        offset: this.dataInitial?.offset,
      }

      // this.$store.dispatch('updateAjaxDialog', [true, true]);
      this.loading = true

      await axios({
        method: 'GET',
        url: url,
        headers: {
          'X-CSRF-TOKEN': token,
        },
        params,
      })
        .then( (response) => {
          // this.$store.dispatch('updateAjaxDialog', [false, false]);
          this.loading = false

          if ( response?.data?.status === 'success' ) {
            const columns = response.data.data.columns;
            data = response.data.data.rows;
            this.length = response.data.data.count;
            this.pageCount = Math.ceil(response.data.data.count / this.defaultPerPage);

            //set headers without association columns
            this.headers = []
            for (let c in columns) {
              let item = {
                align: 'start',
                name: columns[c].name,
                rules: [],
                show_in_popup: true,
                show_in_table: true,
                sortable: true,
                text: columns[c]?.label || columns[c].name,
                value: columns[c].name,
                type: columns[c].type,
              }

              if (!columns[c]?.association) {
                this.headers.push(item);
              }
            }

            this.headers.push({
              text: this.$t('modules.customdata.table.column_actions'),
              value: 'actions',
              type: 'actions',
              sortable: false,
              width: '110',
            });

             // set default columns for columns_chooser
            this.$store.dispatch('customDataSetDefaultColumnsData', this.headers)

            //operations with headers and columns_chooser
            if(this.custom_data_columns_data_activated.length) {
              this.$store.dispatch('customDataSetColumnsDataFromActivated')
            } else {
              if (this.custom_data_saved_columns_data.length) {
                this.$store.dispatch('customDataSetColumnsData', this.custom_data_saved_columns_data)
              } else {
                this.$store.dispatch('customDataSetColumnsData', this.headers)
              }
            }
          }
        },
        (err) => {
          this.$store.dispatch('updateAjaxDialogError', [ err, 'Ошибка', 'обновления' ]);
        }
      );

      return data;
    },

    /**
     * Save button in edit modal
     * @method save
     */
    async save() {
      if (this.$refs.form.validate()) {
        let request = this._.cloneDeep(this.item_obj);

        if (!request.id) {
          request.id = null;
        } else if (request.id && request.id === '') {
          request.id = null;
        }

        if (!request.kw_user_id) {
          request.kw_user_id = null;
        } else if (request.kw_user_id && request.kw_user_id === '') {
          request.kw_user_id = null;
        }

        if (await this.saveCustomEntityList(request, request.id)) {
          this.getItems();
          this.modal = false;
          this.$refs.form.reset()
        }
      } else {
        return false;
      }
    },

    /**
     * @method saveCustomEntityList
     * @param entity
     * @param request
     * @param id
     * */
    async saveCustomEntityList(request, id) {
      let success = false;
      const token = Vue.$cookies.get('token_e')
      const engine_url = this.engine_settings.active_url;
      const bot_id = this.bot_settings.active_id;

      let url = `${engine_url}/kw/custom_modules/${this.multiple_entity}/update/${id}/?bot_id=${bot_id}`
      if(id===null)
        url = `${engine_url}/kw/custom_modules/${this.multiple_entity}/create/?bot_id=${bot_id}`

      this.$store.dispatch('updateAjaxDialog', [true, true]);

      await axios
        .post(
          url,
          request,
          {
            headers: {
              'X-CSRF-TOKEN': token,
            },
          }
        )
        .then(
          (response) => {
            this.$store.dispatch('updateAjaxDialog', [false, false]);
            if (
              response.data &&
              response.data.status &&
              response.data.status === 'success'
            ) {
              success = true;
            }
          },
          (err) => {
            if (
              err &&
              err.response &&
              err.response.data &&
              err.response.data.message_alias
            ) {
              let message_alias = err.response.data.message_alias;

              if (message_alias === 'access_error') {
                this.$store.dispatch('updateAjaxDialogError', [
                  err,
                  this.$t('common.error'),
                  this.$t('common.error_permitions'),
                ]);
              } else {
                this.$store.dispatch('updateAjaxDialogError', [
                  err,
                  this.$t('common.error'),
                  this.$t('common.error_save'),
                ]);
              }
            } else {
              this.$store.dispatch('updateAjaxDialogError', [
                err,
                this.$t('common.error'),
                this.$t('common.error_save'),
              ]);
            }
          }
        );

      return success;
    },

    /**
     * Edit item button
     * @method editItem
     * @param id
     * */
    async editItem(id) {
      //reset form
      if (this.$refs && this.$refs.form) {
        this.$refs.form.reset();
      }

      if (this.entity_data && id) {
        for (let item of this.entity_data) {
          if (item.id && parseInt(item.id) === parseInt(id)) {
            for (let param in item) {
              Vue.set(this.item_obj, param, item[param]);
            }

            this.openModal ()
          }
        }
      }
    },

    /**Delete item button
     * @method deleteItem
     * @param id item id
     * */
    async deleteItem(id) {
      if (confirm(i18n.t('modules.customdata.entity.confirm_delete'))) {
        if (await this.deleteCustomEntityList(id)) {
          this.getItems();
        }
      }
    },

    /**
     * Delete entity from the list
     * @method deleteCustomEntityList
     * @param entity
     * @param id
     */
    async deleteCustomEntityList(id) {
      let success = false;
      const token = Vue.$cookies.get('token_e')
      const engine_url = this.engine_settings.active_url;
      const bot_id = this.bot_settings.active_id;
      const url = `${engine_url}/kw/custom_modules/${this.multiple_entity}/delete/${id}/?bot_id=${bot_id}`

      this.$store.dispatch('updateAjaxDialog', [true, true]);
      await axios
        .delete(
          url,
          {
            headers: {
              'X-CSRF-TOKEN': token,
            }
          }
        )
        .then(
          (response) => {
            this.$store.dispatch('updateAjaxDialog', [false, false]);
            if (
              response.data &&
              response.data.status &&
              response.data.status === 'success'
            ) {
              success = true;

              // this.$store.dispatch('axiosGetCurrentSectionNodesData');
            }
          },
          (err) => {
            if (
              err &&
              err.response &&
              err.response.data &&
              err.response.data.message_alias
            ) {
              let message_alias = err.response.data.message_alias;

              if (message_alias === 'access_error') {
                this.$store.dispatch('updateAjaxDialogError', [
                  err,
                  this.$t('common.error'),
                  this.$t('common.error_permitions'),
                ]);
              } else {
                this.$store.dispatch('updateAjaxDialogError', [
                  err,
                  this.$t('common.error'),
                  this.$t('common.error_delete'),
                ]);
              }
            } else {
              this.$store.dispatch('updateAjaxDialogError', [
                err,
                this.$t('common.error'),
                this.$t('common.error_delete'),
              ]);
            }
          }
        );

      return success;
    },

    /**
     * Export of custom data entities in file
     * @method getExportFile
     */
    getExportFile(fileType) {

      const token = Vue.$cookies.get('token_e')
      const engine_url = this.engine_settings.active_url;
      const bot_id = this.bot_settings.active_id;
      let url = `${engine_url}/kw/custom_modules/${this.multiple_entity}/export_data?`

      //set filters
      if(Object.keys(this.custom_data_selected_filters).length !== 0) {
        url = this.setFiltersUrl(url)
      }

      let params = {
        bot_id: bot_id,
        format: fileType,
      }

      this.$store.dispatch('updateAjaxDialog', [true, true]);
      axios({
        method: 'GET',
        url: url,
        headers: {
          'X-CSRF-TOKEN': token,
        },
        responseType: 'arraybuffer',
        params,
      })
        .then( (response) => {
            this.$store.dispatch('updateAjaxDialog', [false, false]);
            this.$forceFileDownload(response, 'custom_data_' + this.multiple_entity + '-' + bot_id + '.' + fileType)
          },
          (err) => {
            this.$store.dispatch('updateAjaxDialogError', [ err, 'Ошибка', 'Скачивания' ]);
          }
        );

    },

    /**
     * @name clearModel
     * @description Clear all row in table
     * @returns {Promise<void>}
     */
    async clearModel(){
      if (confirm(i18n.t('modules.customdata.model.confirm_clear'))) {
        await this.CustomDataAxiosClearModel({name: this.multiple_entity})
        this.getItems()
      }
    },

    formatDateToString(date) {
      let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-');
    },

    openModal(createMode) {
      (createMode) ? this.modal_create_mode = true : this.modal_create_mode = false
      this.modal = true
    },

    /**
     * @name setFiltersUrl
     * @description prepare Filters from selected and return URL
     * @param url
     */
    setFiltersUrl (url) {
      let requestFilter = Object.entries(this._.cloneDeep(this.custom_data_selected_filters))
        .filter(item => item[1]?.value)

      for (const [key, params] of requestFilter) {
        const pKey = key
        const type = params.type
        const association = params.is_association

        let condition = params.condition?.value
        let value = params.value

        switch (condition) {
          case 'empty':
            condition = 'eq'
            value = 'null'
            break;

          case 'like':
            condition = 'like'
            value = '%' + value + '%'
            break;

          case 'startsWith':
            condition = 'like'
            value = value + '%'
            break;

          case 'endsWith':
            condition = 'like'
            value = '%' + value
            break;

          case 'notLike':
            condition = 'notLike'
            value = '%' + value + '%'
            break;

          case 'active':
            condition = 'eq'
            value = 1
            break;

          case 'notActive':
            condition = 'eq'
            value = 0
            break;

          default:
            console.log('filter conditions no changed')
        }

        if (association) {
          if (value.length > 0) {
            value.map(function(elVal) {
              url += '&filter[' + pKey + '][in][]=' + elVal.id;
            })
          }
        } else {
          if( (type === 'STRING' || type === 'TEXT') && value !== undefined) {
            if (condition === 'multiple' && value.length > 0) {
              value.map(function(elVal) {
                url += '&filter[' + pKey + '][in][]=' + elVal;
              })
            } else {
              url += '&filter[' + pKey + '][' + condition + ']='+ value;
            }
          } else if (type === 'INTEGER' && value !== undefined) {
            if ( (condition === 'between' || condition === 'notBetween') && value.length > 0 ) {
              value.map(function(elVal) {
                url += '&filter[' + pKey + '][' + condition + '][]=' + elVal;
              })
            } else {
              url += '&filter[' + pKey + '][' + condition + ']=' + value;
            }
          } else if (type === 'FLOAT' && value !== undefined) {
            if ( (condition === 'between' || condition === 'notBetween') && value.length > 0 ) {
              value.map(function(elVal) {
                url += '&filter[' + pKey + '][' + condition + '][]=' + elVal;
              })
            } else {
              url += '&filter[' + pKey + '][' + condition + ']=' + value;
            }
            // url += '&filter[' + pKey + '][like]=' + value;
          } else if (type === 'BOOLEAN' && value !== undefined && value !== null) {
            url += '&filter[' + pKey + '][' + condition + ']=' + value;
          } else if (type === 'DATE' && value !== undefined && value.length > 0) {

            //fix if one date in range v-date-picker
            if(value.length < 2){
              let secondDate = new Date(value[0])
              value.push(
                this.formatDateToString(
                  secondDate.setDate( secondDate.getDate() + 1) )
              )
            }

            //rise second date for back request
            if(value.length === 2) {
              let secondDate = new Date(value[1])
              value[1] = this.formatDateToString( secondDate.setDate( secondDate.getDate() + 1) )
            }

            url += '&filter[' + pKey + '][gt]=' + value[0];
            url += '&filter[' + pKey + '][lt]=' + value[1];
          }
        }
      }

      return url
    },

    async resetColumnsChooser() {
      this.headerColumns = []
      await this.customDataResetColumnsDataToDefault(this.multiple_entity)
      this.setHeaderColumnsFromState()
      // this.headerColumns = this._.cloneDeep(this.custom_data_columns_data)
    },

    async resetColumnsChooserForFilter() {
      this.headerColumns = []
      await this.customDataResetColumnsDataForFilterToSaved()
      this.setHeaderColumnsFromState()
      // this.headerColumns = this._.cloneDeep(this.custom_data_columns_data)
    },

    /**
     * @name onSaveFilters
     * @description save and update filters
     * @param data
     * @returns {Promise<void>}
     */
    async onSaveFilters(data){
      let { name, with_columns } = data
      let selectedFilters = Object.entries(this._.cloneDeep(this.custom_data_selected_filters))
        .filter(item => item[1]?.value)

      await this.customDataAxiosSaveFilters(
        {
          nameModule: this.multiple_entity,
          nameFilter: name,
          filters_json: selectedFilters,
          with_columns: with_columns,
        }
      )
    },

    /**
     * @name onDeleteFilter
     * @description delete filter
     * @param name
     */
    onDeleteFilter(name){
      this.$store.dispatch('customDataAxiosDeleteFilter',
        {
          nameModule: this.multiple_entity,
          nameFilter: name,
        }
      )
    },

    setHeaderColumnsFromState() {
      let columns = this._.cloneDeep(this.custom_data_columns_data)

      this._.orderBy(columns, 'position')
      this.headerColumns = columns
    },

    returnFilterKeyOrLabel(key, item) {
      return item?.label ? item?.label : key
    },
  },

  watch: {
    modal: function (val) {
      if (!val && this.item_obj?.id) {
        this.$refs.form.resetValidation();
        this.item_obj = this._.cloneDeep(this.item_empty_obj);
      }
    },

    multiple_entity: async function (value) {
      //clear headers
      this.headerColumns = []
      this.$store.dispatch('customDataSetColumnsData', [])
      this.$store.dispatch('customDataSetColumnsDataFromActivated', 'clear')

      //get filters
      await this.$store.dispatch('customDataAxiosGetFilters', value)

      //get rows and headers
      this.entity_data = await this.getCustomEntityList();

      // this.headerColumns = await this._.cloneDeep(this.custom_data_columns_data)
      this.setHeaderColumnsFromState()

      //show table
      if (!this.load) this.load = true;
    },
  },
};
</script>

<style scoped lang="scss">
.app-wrapper > div > div > div:not(.v-overlay__content) {
  height: auto;
}

.page-title {
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;
}

.cd {
  &__container {
    padding: 16px 12px 22px;
    display: flex;
    flex-direction: column;

    height: calc(100vh - 60px);
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      height: calc(100svh - 60px);
    }
  }

  &__left {
    min-width: 216px;
    max-width: 216px;
    width: 216px;
    padding-left: 8px;
    padding-right: 8px;
    height: 100%;
  }

  &__right {
    padding-left: 8px;
    padding-right: 8px;
    flex: 1;
    height: 100%;
    width: calc(100% - 216px);
    position: relative;

    .table-descr {
      position: absolute;
      top: -20px;
      left: 10px;
    }
  }
}

/* Top navigation for Table */
.cd__top-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  .cd__right {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .v-input {
    max-width: 360px;
  }

  .v-btn::v-deep {
    border-radius: 2px;
    border: 1px solid var(--v-gray-base) !important;
  }

  .v-select {
    max-width: 134px;
    font-size: 14px;
  }
}

/* end Top navigation for Table */

/* Filters and Table */
.cd__bottom-nav {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex: 1;
}

.cd__table {
  display: flex;
  flex-direction: column;
}

/* menus with list */
.v-custom-menu__list{
  border: 1px solid var(--v-gray-base);

  &--btn {
    &:focus:before {
      opacity: 0;
    }
    &[aria-expanded="true"] {
      .v-icon {
        color: var(--v-primary-base) !important;
      }
    }

    &.v-btn--text{

      &[aria-expanded="true"] {
        .v-btn__content {
          color: var(--v-primary-base) !important;
        }

        .v-icon.mdi-chevron-down {
          transform: rotate(180deg);
        }
      }
    }
  }

  &--wrapper::v-deep {
    &.with_fixed-height {
      //height: 380px;
    }

    .__rail-is-vertical {
      right: 0 !important;
    }

    .v-list-group {
      &__items {
        background-color: var(--v-gray2-base) !important;

        .v-list-item__content {
          padding: 0 64px !important;
        }
      }
    }

  }

  .v-list {
    padding: 5px 0 10px !important;

    .v-subheader {
      height: auto;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      padding: 15px 20px 5px;
    }

    .v-list-item {
      //min-height: 32px;
      //max-height: 34px !important;

      &.v-list-item--active::before {
        opacity: 0;
      }

      .v-list-item__action{
        margin: 5px 12px 4px 0;
      }
      .v-list-item__content {
        padding: 6px 0;
        .v-list-item__title {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }

  &--filter-btns::v-deep {
    border: 1px solid var(--v-gray-base);
    background-color: white;
    height: 48px;
    min-height: 48px;
    flex: none;

    &.active{
      border-color: var(--v-gray-base);
    }

    .v-btn {
      border: none !important;
      height: 100% !important;
      //min-width: inherit !important;
      //width: auto !important;
      padding: 0 6px !important;
    }

    .filter-btns {
      &__btn-show {
        min-width: 36px !important;
      }
      &__btn-menu {
        //flex: auto !important;
        min-width: 36px !important;
      }
    }
  }
}

.draggable-ghost {
  opacity: 0.5;
  background: var(--v-primaryLight-base);
}
/* end menus with list */

.settings-form::v-deep {
  height: 100%;

  .v-card {
    height: 100%;
    display: flex;
    flex-direction: column;

    .v-card__text{
      flex-grow: 1;
      overflow: auto;
    }
  }
}

</style>
