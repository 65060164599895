<template>
  <v-app>
    <AxiosPreloaderAndResponse />

    <router-view></router-view>
  </v-app>
</template>

<script>
import AxiosPreloaderAndResponse from './components/AxiosPreloaderAndResponse.vue';
import { mapGetters } from 'vuex';
import HELPERS from '@/modules/OperatorPanel/helpers';
import i18n from "@/lang/i18n";

export default {
  name: 'MainApp',

  sockets: {
    notification(data) {
      const options = {
        position: 'top-right',
        timeout: 5000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        closeButton: 'button',
        icon: true,
      };

      let message = data.message;

      if (data.message_alias) {
        switch (data.message_alias) {
          case 'broadcast_success':
            message = this.$t('notification.broadcast_success');

            break;

          case 'broadcast_error':
            message = this.$t('notification.broadcast_error');

            break;
        }
      }

      this.$toast(message, { ...options, ...data.options });
    },

    new_message(data) {
      this.messagesListener(data);
    },

    chat_room_update(data) {
      this.$store.dispatch('opSetChatNotification', 'update');

      if (data.data.status === 'new') {

      console.log('chat_room_update',data)
        HELPERS.showBrowserNotification({
          title: this.$t('modules.op.texts.new_message'),
          body: '',
        });
      }
    },

    connect_error(err) {
      HELPERS.handleError([err, i18n.t("common.error"), i18n.t("common.error_network")]);
    },

    connect() {
      this.$socket.client.emit('login', this.opUserInfo?.id);
    },
  },

  beforeCreate() {

    //set language for html attr lang from cookie
    let user_lang = this.$getCookie('user_lang')
    if(user_lang){
      document.documentElement.setAttribute("lang", user_lang);
    }
  },

  created() {
    window.onbeforeunload = () => {
      if (this.opUserInfo && this.bot_settings?.active_id) {
        this.$store.dispatch('opSetUserStatus', {
          status_id: 2,
          bot_id: this.bot_settings.active_id,
        });
      }
    };

    /* for DEBUGGING State */
    // this.$store.subscribeAction((action, state) => {
    //   console.log('ACTIONNN SUBSCRIBER', action.type,)
    // })
    // this.$store.subscribe((mutation, state) => {
    //   console.log('MUTATION SUBSCRIBER', mutation)
    // });
  },

  computed: {
    ...mapGetters([
      'opChatRooms',
      'opActiveChatRoom',
      'opUserInfo',
      'bot_settings',
    ]),
  },

  watch: {
    opUserInfo: function (user) {
      if (user?.id) {
        this.$socket.client.emit('login', user.id);
      }
    },

    /* for DEBUGGING Route */
    // '$route' (to, from) {
    //   console.log('Route changed from ' + from.path + ' to ' + to.path);
    // },
  },

  methods: {
    async messagesListener(event) {
      const message = event.data;

      if (message.system || message.isUser) {
        return;
      }

      const authorId = message?.participant_id;
      const text = message?.message_text;

      const isEqualAuthorId = (participant) => participant.id === authorId;

      const getChatRoom = (room) => {
        const participants = room.participants || [];
        return participants.some(isEqualAuthorId);
      };

      const getParticipant = () => {
        const chatRoom = this.opChatRooms.find(getChatRoom);

        if (chatRoom === undefined) {
          return null;
        }

        const participant = chatRoom.participants.find(isEqualAuthorId);

        return participant;
      };

      const authorObj = getParticipant(authorId);

      if (authorObj.user_id === this.opUserInfo.id) {
        return;
      }

      const author = authorObj.client || authorObj.user;

      const authorName = HELPERS.formatClientName(author);

      console.log('messagesListener', event);

      HELPERS.showBrowserNotification({
        title: authorName || this.$t('modules.op.texts.new_message'),
        body: text,
      });

      const urlParams = new URLSearchParams(window.location.search);

      if (
        (!this.opActiveChatRoom?.id && !urlParams.get('chat_room_id'))
        || (+urlParams.get('chat_room_id') !== event.data.chat_room_id)
      ) {
        this.$store.dispatch('opSetChatNotification', event);
      } else if (this.opActiveChatRoom?.id === event.data.chat_room_id) {
        this.$store.dispatch('opAddMessageToActiveChatRooms', event);
      }

      // if (
      //   (!this.opActiveChatRoom?.id && !urlParams.get('chat_room_id')) ||
      //   (urlParams.get('chat_room_id') &&
      //     this.opActiveChatRoom?.id !== +urlParams.get('chat_room_id'))
      // ) {
      //   this.$store.dispatch('opSetChatNotification', event);
      // }
    },
  },

  components: {
    AxiosPreloaderAndResponse,
  },
};
</script>
